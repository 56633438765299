import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import Contact from "../components/contact"

const ContactPage = ({ data }) => (
  <Layout>
    <Contact
      contact={data.contactPageData.frontmatter.contact}
      images={data.aboutUsImages.frontmatter.images}
    />
  </Layout>
)

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export const pageQuery = graphql`
  query ContactPageQuery {
    contactPageData: markdownRemark(
      frontmatter: { templateKey: { eq: "home-page" } }
    ) {
      frontmatter {
        contact {
          title
          description
          phone
          mobile
          email
        }
      }
    }
    aboutUsImages: markdownRemark(
      frontmatter: { templateKey: { eq: "about-page" } }
    ) {
      frontmatter {
        images {
          image {
            id
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`

export default ContactPage
