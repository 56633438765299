import React, { Component } from "react"
import styled from "styled-components"
import { FaPhone, FaMobileAlt, FaEnvelope } from "react-icons/fa"
import ItemGallery from "./ItemGallery"

const ConatactWrapper = styled.section`
  margin: 2rem 0;
  .contact-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 2rem;
    @media (max-width: 880px) {
      grid-template-columns: 1fr;
    }
    .gallery {
      margin: 2rem 0;
      display: flex;
      justify-content: space-between;
      grid-column: span 2;
      @media (max-width: 880px) {
        grid-column: span 1;
      }
    }
    .col-2 {
      background-color: #f6f6f6;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      padding: 20px;

      /* form {
        margin: 0;
        width: 80%;
        display: flex;
        flex-direction: column;
        padding: 4rem 2rem;
        label {
          display: block;
        }
        input,
        textarea {
          width: 100%;
          display: block;
          padding: 0.4rem 1rem;
          border-radius: 5px;
          border-color: transparent;
        }
        p {
          margin-bottom: 2rem;
        }
        button {
          border: transparent;
          padding: 0.6rem 1.2rem;
          background-color: #ee7879;
          letter-spacing: 1.2px;
          color: white;
          text-shadow: 1px 1px 3px black;
          border-radius: 5px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
          transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
          :hover {
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
              0 10px 10px rgba(0, 0, 0, 0.22);
          }
        }
      } */
    }
  }
  /* .hide-element {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  } */
  .contact-details {
    div {
      display: flex;

      h2 {
        min-width: 200px;
        margin-right: 1rem;
        @media (max-width: 480px) {
          margin-right: 0;
          width: 100%;
        }
      }
      time {
        margin-bottom: 1.45rem;
      }
      @media (max-width: 480px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
    .phone-and-mail {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .phone {
        display: flex;
        align-items: center;
        color: #ee7879;
      }
      a {
        color: #ee7879;
        text-decoration: none;
        transition: all 0.1s ease-in-out;

        :hover {
          text-decoration: underline;
        }
      }
    }
  }
`

class Contact extends Component {
  render() {
    const { contact, id, images } = this.props
    const phoneNumber = contact.phone.replace(/\s/g, "")
    const mobileNumber = contact.mobile.replace(/\s/g, "")
    return (
      <ConatactWrapper>
        <div className="container contact-container" id={id}>
          <div className="col-1">
            <article itemScope itemType="http://schema.org/LocalBusiness">
              <header>
                <h1 itemProp="name">{contact.title}</h1>
                <p itemProp="description">{contact.description}</p>
              </header>
              <div className="contact-details">
                <div>
                  <h2>Adres</h2>
                  <p
                    itemProp="address"
                    itemScope
                    itemType="http://schema.org/PostalAddress"
                  >
                    <span itemScope itemType="http://schema.org/Person">
                      <span itemProp="name">Irena i Michał Wojnik</span>
                    </span>
                    <br />
                    <span itemProp="streetAddress">Berezka 49</span>
                    <br />
                    <span itemProp="postalCode">38-610</span>
                  </p>
                </div>
                <div>
                  <h2>Zapraszamy</h2>
                  <time itemProp="openingHours" dateTime="Mo-Su">
                    24 godziny na dobę
                    <br />7 dni w tygodniu
                  </time>
                </div>
              </div>
            </article>
          </div>
          <div className="col-2">
            <div className="contact-details">
              <h2>Kontakt</h2>
              <div className="phone-and-mail">
                <span className="phone">
                  <FaPhone style={{ marginRight: "1rem" }} />
                  <a href={`tel:${phoneNumber}`} itemProp="telephone">
                    {contact.phone}
                  </a>
                </span>
                <span className="phone">
                  <FaMobileAlt style={{ marginRight: "1rem" }} />
                  <a href={`tel:${mobileNumber}`} itemProp="telephone">
                    {contact.mobile}
                  </a>
                </span>
                <span className="phone">
                  <FaEnvelope style={{ marginRight: "1rem" }} />
                  <a href={`mailto:${contact.email}`} itemProp="email">
                    {contact.email}
                  </a>
                </span>
              </div>
            </div>
            {/* <form
              name="contact"
              method="post"
              data-netlify="true"
              action="/success"
              netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              <p className="hide-element">
                <label>
                  Nie wypełniaj tego pola jeśli jesteś człowiekiem
                  <input name="bot-field" />
                </label>
              </p>
              <p>
                <label htmlFor="name" className="hide-element">
                  Imie:
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Imie*"
                  required
                />
              </p>
              <p>
                <label htmlFor="phone" className="hide-element">
                  Numer telefonu:
                </label>
                <input
                  type="phone"
                  name="phone"
                  id="phone"
                  placeholder="Telefon"
                />
              </p>
              <p>
                <label htmlFor="email" className="hide-element">
                  Adres email:
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email*"
                  required
                />
              </p>
              <p>
                <label htmlFor="start-date" style={{ fontSize: "0.8rem" }}>
                  Planowana data rozpoczęcia pobytu:
                </label>
                <input type="date" name="start-date" id="start-date" />
              </p>
              <p>
                <label htmlFor="end-date" style={{ fontSize: "0.8rem" }}>
                  Planowana data zkończenia pobytu:
                </label>
                <input type="date" name="end-date" id="end-date" />
              </p>
              <p>
                <label htmlFor="guests" className="hide-element">
                  Liczba gości:
                </label>
                <input
                  type="number"
                  name="guests"
                  id="guests"
                  placeholder="Liczba gości"
                  min="1"
                />
              </p>
              <p>
                <label htmlFor="message" className="hide-element">
                  Wiadomość:
                </label>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Napisz wiadomość"
                  rows="5"
                />
              </p>
              <button type="submit">Wyślij</button>
            </form> */}
          </div>
          {images ? (
            <div className="gallery">
              <ItemGallery gallery={images} />
            </div>
          ) : null}
        </div>
      </ConatactWrapper>
    )
  }
}

export default Contact
